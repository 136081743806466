@import '../App';
.apply-button {
    border-radius: 5px;
    border: none;
    font-family: 'Lato Bold', sans-serif;
    padding: 8px 20px;
    margin-top: 6px;
    width: 180px;
    //   height: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: $font-size-filter-dropdown;
    &.active {
        color: white !important;
        background-color: #4f4f4f;
        cursor: pointer;
        &:hover {
            background-color: #3e3e3e;
        }
    }
    &.inactive {
        background-color: #d9d9d9;
        color: #b9b9b9 !important;
        cursor: default;
    }
}

.filter-button-container {
    position: relative;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
}

.select-unselect-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    gap: 10px;
    .dot-seperator {
        color: $brand-dark-grey;
        font-size: 0.5em;
        // display: flex;
        align-items: center;
        // justify-content: flex-start;
        // flex: 1;
    }
    .select-button {
        display: flex;
        align-items: left;
        color: #3268cd;
        font-family: 'Lato Bold', sans-serif;
        font-size: $font-size-filter-dropdown;
        cursor: pointer;
        // display: flex;
        // align-items: center;
        // justify-content: flex-start;
        // &:last-child {
        // flex: 1;
        // }
        // &.sel {
        //     // justify-content: flex-start;
        //     // flex: 1;
        // }
        // &.unsel {
        //     // flex: 1;
        // }
        &:hover {
            display: flex;
            align-items: center;
            text-decoration: underline;
        }
    }
    .escape-button {
        display: flex;
        align-items: center;
        margin-left: auto;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        cursor: pointer;
        // background-color: black;
        // border: 1px solid #3268cd;
        svg {
            font-size: 1.2em;
            color: $brand-dark-grey;
        }
        &:hover {
            background-color: $light-grey-hover;
        }
    }
}

.filter-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 20px;
    outline: none;
    height: 20px;
    gap: 6px;
    color: $brand-dark-grey;
    transition:
        background-color 0.1s,
        color 0.1s;
    .sort-logo {
        filter: invert(30%) sepia(9%) saturate(11%) hue-rotate(23deg)
            brightness(96%) contrast(91%);
        align-self: center;
        height: 20px;
        width: 20px;
    }
    &:hover {
        background-color: #f7f7f7;
    }
    &.applied {
        background-color: $brand-color-hover;
        border: 1px solid $brand-color-hover;
        color: white;
        .sort-logo {
            filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(2deg)
                brightness(106%) contrast(106%);
        }
        &:hover {
            background-color: lighten($brand-color-hover, 3%);
            border: 1px solid lighten($brand-color-hover, 3%);
        }
    }
}

.filter-button svg {
    font-size: 1.1em;
}

.filter-title {
    font-family: 'Lato Bold', sans-serif;
    // font-weight: 600;
    font-size: 1em;
    white-space: nowrap;
}

.filter-dropdown {
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    padding-top: 10px;
    z-index: 99999;
    position: absolute;
    margin-top: 5px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    top: 100%;
    right: 8px;
    width: 220px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
    &.sort {
        width: 150px;
        padding: 0px;
        overflow: hidden;
    }
    &.mobile {
        left: 1px;
    }
}

.sort-option {
    font-size: 1em;
    padding: 10px 10px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    width: calc(100% - 20px);
    color: $brand-dark-grey;
    cursor: pointer;
    &:hover {
        background-color: $light-grey-hover;
    }
    &:last-child {
        border-bottom: 0px;
    }
    &.applied {
        background-color: lighten($brand-color-hover, 35%);
        color: $brand-color-hover;
        .sort-label {
            font-family: 'Lato Bold', sans-serif;
        }
    }
    .sort-icon {
        width: 30px;
        align-items: flex-start;
        text-align: center;
    }
    .sort-label {
        margin-left: 5px;
    }
}

.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    padding-bottom: 6px;
    width: 100%;
    &:last-child {
        padding-bottom: 0px;
    }
    &:hover {
        .checkbox {
            border-color: #4f4f4f;
        }
        .checkbox.checked {
            background: #3e3e3e;
            border-color: #3e3e3e;
        }
    }
}

.checkbox-label input[type='radio'] {
    margin: 0px;
    border: 10px solid #90ddd0;
    // border: 1px solid #d9d9d9;
    cursor: pointer;
    background-color: #3e3e3e;
}

.checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    transition: all 150ms;
    cursor: pointer;
    .fa-check {
        visibility: hidden;
    }
    &.checked {
        background: #4f4f4f;
        border: 1px solid #4f4f4f;
        .fa-check {
            visibility: visible;
            font-size: 10px;
            color: white;
        }
    }
}

.checkbox-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* Aligns elements to the left */
    flex: 1;
    /* Takes up remaining space */
    font-size: $font-size-filter-dropdown;
}

.checkbox-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* Aligns the element to the right */
    font-size: $font-size-filter-dropdown;
    margin-right: 5px;
}
