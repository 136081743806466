// @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@font-face {
    font-family: 'Font Awesome 5 Solid';
    src: url('fa-solid-900.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Bold';
    src: url('/fonts/Lato-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

$image-compliance-black: #000000;
$light-grey-hover: #f7f7f7;
$brand-dark-grey: #4f4f4f;
$brand-middle-grey: #696969;
$brand-white: #ffffff;
$brand-color-dark: #0c0b1f;
$brand-color-hover: #e5b611;
$font-size-main: 1.8em;
$font-size-large: 1.2em;
$font-size-medium: 1em;
$font-size-small: 0.9em;
$font-size-default: 0.8em;
$font-size-smaller: 0.4em;
$font-size-filter-dropdown: 0.9em;
$navbar-height: 50px;
* {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
}

html,
body {
    height: 100%;
    margin: 0;
    overscroll-behavior-x: none;
}

.App {
    // text-align: center;
    // padding-top: $navbar-height;
    z-index: 0;
    width: 100%;
    // position: fixed;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.App-header {
    background-color: $light-grey-hover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: $font-size-medium;
}

.App-navbar {
    position: fixed;
    z-index: 100;
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    // height: 100%;
    height: $navbar-height;
    background-color: $brand-white;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    padding: 0px 20px;
}

.navbar-wrapper {
    height: $navbar-height;
    // overflow: auto;
}

.mobile-navbar {
    padding: 20px;
    width: calc(100% - 40px);
    // height: 100px;
    // margin-top: 50px;
    // position: absolute;
    // top: 0;
    // right: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    z-index: 100000;
    align-items: center;
    background-color: white;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid $light-grey-hover;
    border-bottom-color: $brand-color-hover;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.current-time-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    font-size: $font-size-medium;
    width: 120px;
    font-weight: 600;
}

.nav-title-container {
    display: flex;
    justify-content: left;
    align-items: center;
    flex: 1;
    font-size: $font-size-main;
    font-weight: 400;
    color: $image-compliance-black;
    white-space: nowrap;
}

.login-title-container {
    text-align: center;
    font-size: $font-size-medium;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 40px;
    border-radius: 20px;
    width: 300px;
    &.mobile {
        width: 250px;
        padding: 30px;
    }
    .brand-logo-login {
        max-height: 35px;
        width: auto;
        height: auto;
        // padding-right: 20px;
    }
    .login-title {
        margin-top: 25px;
        margin-bottom: 30px;
        font-size: 1.7em;
    }
    .login-button {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: $brand-color-hover;
        color: white;
        height: 30px;
        border-radius: 5px;
        font-family: 'Lato Bold', sans-serif;
        font-size: $font-size-large;
        cursor: pointer;
        &:hover {
            background-color: lighten($brand-color-hover, 3%);
        }
    }
}

.green-button {
    background-color: #228c22;
    border-radius: 100%;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 10px 20px;
    color: white;
    text-align: center;
    cursor: pointer;
    font-size: $font-size-smaller;
    font-weight: 600;
}

.green-button:hover {
    background-color: rgb(126, 214, 126);
}

.units {
    min-width: 60px;
    text-align: center;
}

.nav-button {
    background-color: $brand-white;
    border: none;
    color: $brand-dark-grey;
    text-align: left;
    cursor: pointer;
    font-size: $font-size-large;
    font-family: 'Lato Bold', sans-serif;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 15px;
    white-space: nowrap;
    gap: 5px;
    border-radius: 20px;
    svg {
        height: $font-size-large;
    }
    .nav-icon-mobile {
        font-size: 1.1em;
    }
    .fa-bars {
        font-size: 1.4em;
    }
    &:not(.mobile-icon):hover {
        // background-color: $brand-color-hover;
        // color: #ffffff;
        background-color: $light-grey-hover !important;
        // color: $brand-color-hover;
    }
    &.mobile-icon:active {
        background-color: $brand-color-hover;
        color: #ffffff;
    }
    &.selected {
        background-color: $light-grey-hover !important;
        border-radius: 20px;
        border: none;
        color: $brand-dark-grey;
        text-align: left;
        cursor: pointer;
        font-size: $font-size-large;
        font-family: 'Lato Bold', sans-serif;
        display: flex;
        align-items: center;
        height: 30px;
        padding: 15px;
        &:hover {
            color: $brand-dark-grey;
        }
    }
    &.add {
        background-color: $brand-color-hover !important;
        border-radius: 20px;
        border: none;
        color: white;
        text-align: left;
        cursor: pointer;
        font-size: $font-size-large;
        font-family: 'Lato Bold', sans-serif;
        display: flex;
        align-items: center;
        height: 30px;
        padding: 15px;
        margin: 0px 8px;
        border-radius: 10px;
        &:hover {
            background-color: darken($brand-color-hover, 5%) !important;
            color: white;
            border-radius: 10px;
        }
    }
}

.add-modal {
    position: absolute;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    margin-top: 5px;
    // bottom: -40px;
    right: 20px;
    // left: -100px;
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* Align buttons to the left */
    button {
        padding: 10px;
        width: 150px;
        height: 110px;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        background-color: transparent;
        color: $brand-dark-grey;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon-container {
            flex: 1;
            display: flex;
            align-items: center;
            svg {
                font-size: 2.5em;
            }
        }
        p {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 1.1em;
            font-family: 'Lato Bold', sans-serif;
            margin: 0px;
            // margin-top: 20px;
        }
        &:hover {
            background-color: rgba($brand-color-hover, 0.05);
            color: $brand-color-hover;
            border: 1px solid rgba($brand-color-hover, 0.1);
        }
    }
}

.nav-button-mobile {
    background-color: $brand-white;
    border: none;
    color: $brand-dark-grey;
    cursor: pointer;
    font-size: $font-size-large;
    font-family: 'Lato Bold', sans-serif;
    box-sizing: border-box;
    text-align: center;
    height: 30px;
    width: 100%;
    svg {
        height: $font-size-large;
    }
    .nav-icon-mobile {
        font-size: 1.1em;
    }
    .fa-bars {
        font-size: 1.4em;
    }
    &:active {
        background-color: $brand-color-hover;
        color: #ffffff;
        border-radius: 20px;
    }
    &.selected {
        background-color: $light-grey-hover !important;
        border-radius: 20px;
        border: none;
        color: $brand-dark-grey;
        cursor: pointer;
        font-size: $font-size-large;
        font-family: 'Lato Bold', sans-serif;
        // display: flex;
        align-items: center;
        height: 30px;
        text-align: center;
        // padding: 15px;
    }
}

.submit-button {
    cursor: pointer;
    padding: 10px 40px;
    font-size: $font-size-medium;
    margin-bottom: 40px;
    margin-top: 20px;
    border-radius: 20px;
    margin-left: 0px;
    margin-right: 40px;
}

// .logout-button {
//     margin: 0px;
//     height: 40px;
//     background-color: #c0c0c0;
//     border-radius: 5px;
//     color: #ffffff;
// }
.grey-button {
    background-color: #c0c0c0;
    cursor: not-allowed;
}

.grey-button:hover {
    background-color: #c0c0c0;
}

.buttons-container {
    display: flex;
    width: 80%;
}

.left-aligned-button {
    margin-right: auto;
}

.brand-name {
    padding-top: 30px;
    font-size: $font-size-medium;
}

.compliance-table thead tr {
    background-color: $brand-white;
}

.compliance-table tfoot tr td {
    border: none;
    background-color: #ffffff;
    padding-top: 20px;
}

.compliance-table {
    border-collapse: collapse;
    background-color: $brand-white;
    color: $image-compliance-black;
    width: 100%;
}

.compliance-table th {
    font-size: 1.1rem;
    text-align: left;
    font-family: 'Lato Bold', sans-serif;
}

.compliance-table td,
.compliance-table th {
    padding: 8px;
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid #d9d9d9;
    padding-left: 0;
    padding-right: 16px;
}

th {
    font-family: 'Lato Bold', sans-serif;
    font-size: $font-size-main;
}

.compliance-table td {
    color: black;
}

.grey-row {
    background-color: #fcfcfc;
}

/*

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px $image-compliance-black;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border: 1px solid $image-compliance-black;
}*/

.brand-logo {
    max-height: 28px;
    width: auto;
    height: auto;
    padding-right: 20px;
}

.cog-icon-container {
    padding-right: 10px;
    font-size: 30px;
    color: $image-compliance-black;
    cursor: pointer;
}

.settings-actions {
    width: 120px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    // padding-right: 10px;
    &.mobile {
        gap: 0px;
    }
}

.cog-icon-container:hover {
    color: rgb(255, 100, 100);
}

.back-button {
    padding: 10px 16px;
    margin-right: 10px;
    background-color: $image-compliance-black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
}

.back-button:hover {
    background-color: rgb(255, 100, 100);
}

.fa-padded-right {
    padding-right: 8px;
}

.fa-padded-left {
    padding-left: 2px;
}

.compliance-table .centered {
    text-align: center;
}

.quantity-button {
    width: 65px;
}

.search-input {
    display: flex;
    align-items: center;
}

// .filter-controls input {
//     height: 28px;
// }
.search-container input {
    border-radius: 20px;
    border: #cccccc;
    border-style: solid;
    padding: 4px;
    border-width: 1px;
    height: 12px;
}

// .filter-controls .search-container input {
//     border-radius: 20px;
//     border: #cccccc;
//     border-style: solid;
//     padding: 4px;
//     border-width: 1px;
//     height: 12px;
// }
.decision-status-0 {
    background-color: green;
}

.decision-status-1 {
    background-color: yellow;
}

.decision-status-2 {
    background-color: red;
}

.thumbnail {
    max-width: 80px;
    border-width: 1px;
    border-style: solid;
    border-color: #000000;
}

.largeimage {
    width: 464px;
    border-width: 1px;
    border-style: solid;
    border-color: grey;
}

.icon::before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Solid';
}

h1,
h2,
h3,
h4 {
    text-align: left;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 16px;
}

.wrapper-container {
    padding: 30px;
    max-width: 2000px;
    margin: auto;
}

// input {
//     border: #cccccc;
//     border-style: solid;
//     padding: 6px;
//     border-width: 1px;
//     height: 24px;
// }
.text-filter input {
    width: 80px;
    border-color: grey;
    border-radius: 5px;
}

.shadow-container {
    padding: 0px;
    overflow: hidden;
}

.upload-components {
    // width: 400px;
    width: 50%;
    // text-align: center;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    // background: #fff;
    // box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 30px;
}

.upload-title {
    text-align: center;
    margin-top: 0;
}

.upload-container {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 30px;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
    .modal-container {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        &.mobile {
            width: 300px;
        }
        // gap: 20px;
        // padding: 20px;
        // padding-top: 0;
        // margin: 0;
        position: relative;
        .modal-close-button {
            position: absolute;
            top: 3px;
            right: 3px;
            transform: translate(50%, -50%);
            width: 6px;
            height: 6px;
            background-color: $brand-dark-grey;
            border: none;
            border-radius: 50% !important;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px !important;
            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 2px;
                height: 50%;
                background-color: #fff;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
            &:hover {
                background-color: #3e3e3e;
            }
        }
    }
}

.delete-modal-content {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    h3 {
        margin-top: 5px;
        text-align: center;
    }
    .delete-text {
        text-align: center;
    }
    .button-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-left: auto;
        .cancel-button {
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            margin-top: 20px;
            justify-content: center;
            align-items: center;
            color: rgb(65, 65, 65);
            border: 1px solid $brand-dark-grey;
            text-align: center;
            cursor: pointer;
            &:hover {
                background-color: $light-grey-hover;
            }
        }
        .delete-button {
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            margin-top: 20px;
            justify-content: center;
            align-items: center;
            color: white !important;
            background-color: $brand-dark-grey;
            text-align: center;
            margin-left: auto;
            cursor: pointer;
            &:hover {
                background-color: $brand-dark-grey;
            }
        }
    }
    .logout-button {
        border-radius: 5px;
        border: none;
        font-family: 'Lato Bold', sans-serif;
        padding: 10px 0px;
        margin-top: 20px;
        width: 100%;
        //   height: 24px;
        justify-content: center;
        align-items: center;
        font-size: $font-size-large;
        color: white !important;
        background-color: $brand-dark-grey;
        text-align: center;
        cursor: pointer;
        &:hover {
            background-color: #3e3e3e;
        }
    }
}

.confirmation-modal-content {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    h2,
    h3 {
        margin-top: 5px;
        text-align: center;
    }
    p {
        margin-top: 0px;
    }
    textarea {
        height: 100px;
        border-radius: 5px;
        border: 1px solid #bdbdbd;
        padding: 10px;
        resize: none;
    }
    .button-row {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 10px;
        margin-top: 12px;
        .btn-secondary {
            // margin-left: auto;
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            // width: 80px;
            //   height: 24px;
            justify-content: center;
            align-items: center;
            // font-size: $font-size-large;
            // color: white !important;
            // background-color: $brand-dark-grey;
            color: rgb(65, 65, 65);
            border: 1px solid $brand-dark-grey;
            text-align: center;
            cursor: pointer;
            &:hover {
                background-color: $light-grey-hover;
            }
        }
        .btn-primary {
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            // width: 80px;
            //   height: 24px;
            justify-content: center;
            align-items: center;
            // font-size: $font-size-large;
            color: white !important;
            background-color: $brand-dark-grey;
            text-align: center;
            margin-left: auto;
            cursor: pointer;
            &:hover {
                background-color: $brand-dark-grey;
            }
            &:disabled {
                background-color: #d9d9d9;
                color: #b9b9b9 !important;
                cursor: default;
            }
        }
    }
}

.feedback-modal-content {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    h2,
    h3 {
        margin-top: 5px;
        text-align: center;
    }
    p {
        margin: 0px;
    }
    .feedback-note {
        margin-top: 12px;
    }
    textarea {
        margin-top: 12px;
        height: 100px;
        border-radius: 5px;
        border: 1px solid #bdbdbd;
        padding: 10px;
        resize: none;
    }
    .rule-info-table {
        td {
            padding: 5px 1px;
            .check-summary {
                // margin-bottom: 10px;
                color: $brand-dark-grey;
            }
            .check-summary-bullets {
                padding-left: 20px;
                margin: 0px;
                margin-top: 3px;
                li {
                    color: $brand-dark-grey;
                }
            }
        }
        margin-bottom: 10px;
    }
    .button-row {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 10px;
        margin-top: 12px;
        .btn-secondary {
            // margin-left: auto;
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            // width: 80px;
            //   height: 24px;
            justify-content: center;
            align-items: center;
            // font-size: $font-size-large;
            // color: white !important;
            // background-color: $brand-dark-grey;
            color: rgb(65, 65, 65);
            border: 1px solid $brand-dark-grey;
            text-align: center;
            cursor: pointer;
            &:hover {
                background-color: $light-grey-hover;
            }
        }
        .btn-primary {
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            // width: 80px;
            //   height: 24px;
            justify-content: center;
            align-items: center;
            // font-size: $font-size-large;
            color: white !important;
            background-color: $brand-dark-grey;
            text-align: center;
            margin-left: auto;
            cursor: pointer;
            &:hover {
                background-color: $brand-dark-grey;
            }
            &:disabled {
                background-color: #d9d9d9;
                color: #b9b9b9 !important;
                cursor: default;
            }
        }
    }
}

.showcase-modal-content {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    h2,
    h3 {
        margin-top: 5px;
        text-align: center;
    }
    .delete-text {
        margin: 0px;
        text-align: center;
        font-size: 1.05em;
    }
    .button-row {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 10px;
        .email-button {
            margin-left: auto;
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            margin-top: 20px;
            // width: 80px;
            //   height: 24px;
            justify-content: center;
            align-items: center;
            // font-size: $font-size-large;
            // color: white !important;
            // background-color: $brand-dark-grey;
            color: rgb(65, 65, 65);
            border: 1px solid $brand-dark-grey;
            text-align: center;
            cursor: pointer;
            &:hover {
                background-color: $light-grey-hover;
            }
        }
        .delete-button {
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            margin-top: 20px;
            // width: 80px;
            //   height: 24px;
            justify-content: center;
            align-items: center;
            // font-size: $font-size-large;
            color: white !important;
            background-color: $brand-dark-grey;
            text-align: center;
            margin-left: auto;
            cursor: pointer;
            &:hover {
                background-color: $brand-dark-grey;
            }
        }
    }
    .logout-button {
        border-radius: 5px;
        border: none;
        font-family: 'Lato Bold', sans-serif;
        padding: 10px 0px;
        margin-top: 20px;
        width: 100%;
        //   height: 24px;
        justify-content: center;
        align-items: center;
        font-size: $font-size-large;
        color: white !important;
        background-color: $brand-dark-grey;
        text-align: center;
        cursor: pointer;
        &:hover {
            background-color: #3e3e3e;
        }
    }
}

.modal-background {
    .modal-container {
        .user-modal-content {
            width: calc(100% - 40px);
            display: flex;
            flex-direction: column;
            padding: 20px;
            h2 {
                margin-top: 5px;
                text-align: center;
            }
            .user-modal-table {
                // width: calc(100% - 20px);
                padding: 10px 5px;
                gap: 20px;
                display: flex;
                flex-direction: column;
                .user-modal-row {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    .label {
                        width: 40%;
                        font-family: 'Lato Bold', sans-serif;
                        // font-size: $font-size-large;
                    }
                    .value {
                        // font-size: $font-size-large;
                    }
                }
            }
            .logout-button {
                border-radius: 5px;
                border: none;
                font-family: 'Lato Bold', sans-serif;
                padding: 10px 0px;
                margin-top: 20px;
                width: 100%;
                //   height: 24px;
                justify-content: center;
                align-items: center;
                font-size: $font-size-large;
                color: white !important;
                background-color: $brand-dark-grey;
                text-align: center;
                cursor: pointer;
                &:hover {
                    background-color: #3e3e3e;
                }
            }
        }
    }
}

.select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    &.left {
        select {
            text-align: left;
        }
    }
    select {
        height: 30px;
        border-radius: 4px;
        border: 1px solid #bdbdbd;
        appearance: none;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-right: 24px;
        padding: 1px 10px;
        width: 100%;
        cursor: pointer;
        &:hover {
            border: 1px solid $brand-dark-grey;
        }
    }
    &:after {
        font-family: 'Font Awesome 5 Solid';
        content: '\f0d7';
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        pointer-events: none;
        color: $brand-dark-grey;
    }
}

.user-input-text {
    // text-align: center;
    width: calc(100% - 22px);
    height: 28px;
    padding: 0px 10px;
    border-radius: 4px;
    border: 1px solid #bdbdbd;
}

.user-input-dropdown {
    // text-align: center;
    width: 100%;
    height: 28px;
    padding: 0px 10px;
    border-radius: 4px;
    border: 1px solid #bdbdbd;
}

.centered {
    text-align: center;
}

.input-field-title {
    // text-align: center;
    margin: 10px 4px;
    font-size: $font-size-large;
    font-family: 'Lato Bold', sans-serif;
    .required-indicator {
        color: red;
        margin-left: 4px;
    }
}

.upload-box {
    margin: 20px 0px;
    padding: 10px 20px;
    width: calc(100% - 44px);
    height: 120px;
    // background-color: $light-grey-hover;
    background-color: white;
    color: $brand-dark-grey;
    border: none;
    border-radius: 10px;
    border: 2px dashed #bdbdbd;
    cursor: pointer;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    /* stacks children vertically */
    justify-content: center;
    /* center children vertically */
    align-items: center;
    /* center children horizontally */
    &:hover {
        background-color: $light-grey-hover;
    }
    &.dragging {
        background-color: $light-grey-hover;
    }
    .image-icon {
        font-size: 2.5em;
        margin-bottom: 10px;
        pointer-events: none;
    }
    .upload-message {
        font-family: 'Lato Bold', sans-serif;
        margin-bottom: 6px;
        pointer-events: none;
        .browse-text {
            font-family: 'Lato Bold', sans-serif;
            color: #3268cd;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .upload-filetype-message {
        font-size: 0.8em;
        color: #777;
        pointer-events: none;
    }
}

.preview-box {
    margin: 20px 0px;
    padding: 10px 20px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background-color: $light-grey-hover;
    img {
        display: flex;
        max-height: 100px;
        max-width: 150px;
        &.mobile {
            max-width: 120px;
        }
    }
    .file-icon {
        height: 40px;
        color: $brand-dark-grey;
    }
    .info-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        .file-name {
            padding: 6px 0px;
            font-family: 'Lato Bold', sans-serif;
            // font-size: $font-size-large;
            -ms-word-break: break-all;
            word-break: break-all;
            /* Non standard for webkit */
            word-break: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
        }
        .file-size {
            color: #777;
            font-size: 0.9em;
        }
        .approved-container {
            padding: 6px 0px;
            margin-top: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            .approved-box {
                height: 18px;
                width: 18px;
                border-radius: 10px;
                background-color: #228c22;
                display: flex;
                justify-content: center;
                align-items: center;
                .approved-icon {
                    color: white;
                }
            }
            .approved-text {
                color: #228c22;
                font-family: 'Lato Bold', sans-serif;
            }
        }
    }
    .delete-box {
        width: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        cursor: pointer;
        color: $brand-dark-grey;
        // &:hover {
        //     background-color: $light-grey-hover;
        // }
    }
}

.submit-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.submit-container .button {
    background-color: $brand-color-dark;
    border: none;
    color: #ffffff;
    text-align: left;
    cursor: pointer;
    font-size: $font-size-medium;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
}

.submit-container .button:hover {
    background-color: $brand-color-dark;
    color: #ffffff;
}

.submit-button-upload {
    border-radius: 5px;
    border: none;
    font-family: 'Lato Bold', sans-serif;
    padding: 10px 0px;
    margin-top: 30px;
    width: 100%;
    text-align: center;
    //   height: 24px;
    justify-content: center;
    align-items: center;
    font-size: $font-size-large;
    // &.active {
    color: white !important;
    background-color: $brand-dark-grey;
    cursor: pointer;
    &:hover {
        background-color: #3e3e3e;
    }
    // }
    &:disabled {
        background-color: #d9d9d9;
        color: #b9b9b9 !important;
        cursor: default;
    }
}

.upload-progress-container {
    position: fixed;
    bottom: 0px;
    right: 0px;
    padding: 10px 20px;
    gap: 20px;
    display: flex;
    flex-direction: column-reverse;
    z-index: 10000000;
    .upload-progress-box {
        // width: 300px;
        position: relative;
        // height: 60px;
        border-radius: 10px;
        padding: 10px;
        background-color: white;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        gap: 15px;
        .close-button {
            position: absolute;
            top: 2px;
            right: 2px;
            transform: translate(50%, -50%);
            width: 0px;
            height: 0px;
            background-color: $brand-dark-grey;
            border: none;
            border-radius: 50% !important;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px !important;
            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 2px;
                height: 50%;
                background-color: #fff;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
            &:hover {
                background-color: #3e3e3e;
            }
        }
        .image-container {
            display: flex;
            width: 80px;
            img {
                height: 100%;
                width: 80px;
                // height: auto;
                // margin: auto;
                object-fit: cover;
            }
        }
        .right-container {
            width: 250px;
            display: flex;
            p {
                margin: 0px;
            }
            .outcome-container {
                margin-left: auto;
            }
            .info-container {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 8px;
                .asset-title {
                    font-family: 'Lato Bold', sans-serif;
                    // margin: 2px 0px;
                    font-size: $font-size-large;
                    display: inline;
                }
                .view-button {
                    // display: flex;
                    // align-items: center;
                    margin-left: 10px;
                    gap: 2px;
                    font-size: 1em;
                    background-color: transparent;
                    border: none;
                    color: #3268cd;
                    padding: 0px;
                    cursor: pointer;
                    svg {
                        height: 0.9em;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .progress-box {
                    margin-top: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    .progress-info {
                        display: flex;
                        flex-direction: row;
                        .status-text {
                            margin-right: auto;
                            // color: #777;
                            // font-size: 0.9em;
                        }
                        .eta-info {
                            display: flex;
                            gap: 5px;
                            align-items: center;
                            color: #777;
                        }
                    }
                    .progress-bar {
                        margin-top: 5px;
                        width: 100%;
                        height: 6px;
                        border-radius: 3px;
                        background-color: #f6f6f6;
                        overflow: hidden;
                        .progress-color {
                            background-color: #e5b611;
                            height: 100%;
                            border-radius: 3px;
                        }
                    }
                }
                .details-button {
                    color: #3268cd;
                    text-decoration: none;
                    cursor: pointer;
                    font-family: 'Lato Bold', sans-serif;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.results-control {
    display: flex;
    align-items: baseline;
    gap: 10px;
    width: 20%;
}

.settings-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.fifty-percent-container {
    width: 50%;
}

.center-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.settings-controls {
    padding: 60px;
}

.detail-container {
    display: flex;
    padding: 30px;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    // width: calc(100% - 60px);
}

.detail-container > .rules-check-table-container {
    flex: 1;
}

// .detail-container button {
//     padding: 10px 20px;
//     background-color: $brand-dark-grey;
//     color: black;
//     border: none;
//     border-radius: 5px;
//     cursor: pointer;
// }
.details-results-controls {
    display: flex;
    flex-direction: row;
}

.uploaded-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

//////// v2 styles
.results-table-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 0px;
    padding-bottom: 40px;
}

@media screen and (min-width: 1660px) {
    .results-table-grid {
        grid-template-columns: repeat(6, minmax(250px, 1fr));
    }
}

.spec-card {
    border: 1px solid #d9d9d9;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    cursor: pointer;
    p {
        margin: 0px;
    }
    &:hover {
        background-color: $light-grey-hover;
    }
    &.add {
        border: 1px dashed #d9d9d9;
        align-items: center;
        justify-content: center;
        .add-icon {
            font-size: 2em;
            color: #b5b5b5;
        }
        .add-product {
            font-size: 1.2em;
            color: #b5b5b5;
            font-family: 'Lato Bold', sans-serif;
        }
    }
    .upper-container {
        display: flex;
        flex-direction: column;
        // gap: 8px;
        .category-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .category {
                // background-color: rgba(#E5B611, 0.2);
                // color: #E5B611;
                padding: 2.5px 8px;
                border-radius: 10px;
                font-family: 'Lato Bold', sans-serif;
            }
            button {
                margin-left: auto;
                cursor: pointer;
                border: none;
                background-color: transparent;
                svg {
                    font-size: 1.2em;
                    color: $brand-middle-grey;
                }
                &:hover {
                    background-color: $light-grey-hover;
                }
            }
        }
        .spec-name {
            margin-top: 10px;
            font-family: 'Lato Bold', sans-serif;
            font-size: 1.2em;
        }
        .spec-notes {
            margin-top: 4px;
            color: $brand-dark-grey;
        }
    }
    .lower-container {
        margin-top: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        .icon-value-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            p {
                color: $brand-middle-grey;
                white-space: nowrap;
            }
            svg {
                color: $brand-middle-grey;
            }
        }
    }
}

.spec-details {
    width: calc(100% - 60px);
    height: calc(100vh - 60px - 60px);
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    p,
    h2 {
        margin: 0px;
    }
    .header-container {
        display: flex;
        flex-direction: column;
        .header-first-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            .category {
                padding: 3px 10px;
                border-radius: 20px;
                font-size: 1.2em;
                font-family: 'Lato Bold', sans-serif;
            }
            .button-container {
                margin-left: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 10px;
                button {
                    height: 30px;
                    width: 30px;
                    border-radius: 26px;
                    cursor: pointer;
                    border: none;
                    background-color: transparent;
                    svg {
                        font-size: 1.2em;
                        color: $brand-dark-grey;
                    }
                    &:hover {
                        background-color: $light-grey-hover;
                    }
                }
            }
        }
        .spec-notes {
            margin-top: 12px;
            color: $brand-dark-grey;
            font-size: 1.2em;
        }
        .header-last-row {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            .icon-value-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;
                p {
                    color: $brand-middle-grey;
                    white-space: nowrap;
                }
                svg {
                    color: $brand-middle-grey;
                }
            }
        }
    }
    .spec-table {
        .spec-row {
            .spec-key {
                padding: 15px 0px;
                padding-right: 10px;
                font-family: 'Lato Bold', sans-serif;
                min-width: 150px;
            }
            .spec-value {
                padding: 15px 0px;
                .spec-value-list {
                    margin: 0px;
                    padding-left: 15px;
                }
                .nutrition-container {
                    display: flex;
                    .nutrition-item {
                        height: 90px;
                        width: 60px;
                        box-shadow: 0px 0px 0px 1px black inset;
                        // outline: 1px solid;
                        overflow: hidden;
                        &:not(:first-child) {
                            margin-left: -1px;
                        }
                        .mask {
                            position: relative;
                            width: 90px;
                            height: calc(92px);
                            // top: 1px;
                            left: -15px;
                            border: 1px;
                            // margin: 0 auto;
                            overflow: hidden;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            .fop-content {
                                width: 58px;
                                display: flex;
                                flex-direction: column;
                                // padding: 8px 0px;
                                text-align: center;
                                height: calc(90px);
                                .fop-label-div {
                                    margin-top: 6px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex: 1;
                                    .fop-label {
                                        width: 100%;
                                        font-family: 'Lato Bold', sans-serif;
                                    }
                                }
                                .fop-value-div {
                                    flex: 1.7;
                                    width: 100%;
                                    border-bottom: 1px solid;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    p {
                                        align-items: center;
                                        /* Center vertically */
                                        justify-content: center;
                                        /* Center horizontally */
                                        font-family: 'Lato Bold', sans-serif;
                                        font-size: 1.3em;
                                        // flex: 1;
                                        &.small {
                                            font-size: 1.1em;
                                        }
                                    }
                                }
                                .fop-pct-div {
                                    flex: 1.4;
                                    display: flex;
                                    justify-content: center;
                                    padding-bottom: 4px;
                                    align-items: center;
                                    .fop-pct {
                                        width: 100%;
                                        font-size: 1.3em;
                                        font-family: 'Lato Bold', sans-serif;
                                        align-self: auto;
                                    }
                                }
                            }
                        }
                        .mask:after {
                            content: '';
                            position: absolute;
                            left: 1px;
                            top: 1px;
                            border-radius: 100%;
                            outline: 1px solid;
                            width: 88px;
                            height: 88px;
                            box-shadow: 0px 0px 0px 2000px white;
                        }
                    }
                }
                .spec-content-table {
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                    border-collapse: collapse;
                    tr {
                        &:first-child {
                            border-bottom: 1px solid;
                            td {
                                padding: 3px 0px;
                                padding-right: 15px;
                                font-family: 'Lato Bold', sans-serif;
                            }
                        }
                        td {
                            padding-right: 15px;
                        }
                    }
                }
            }
        }
    }
}

.rule-result-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    border: 1px solid white;
    cursor: pointer;
    background: white;
    overflow: hidden;
    width: calc(100% - 24px);
    height: auto;
    position: relative;
    border: 1px solid #d9d9d9;
    padding: 12px;
    border-radius: 10px;

    &:hover {
        img.main-image {
            transform: scale(1.05);
        }
        background-color: $light-grey-hover;
    }

    .main-image-container {
        width: 100%;
        padding-top: 100%;
        position: relative;
        // background: #f8f8f8;
        overflow: hidden;
        border-radius: 10px;
    }
    img.main-image {
        position: absolute;
        border-radius: 10px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease;
    }
    .info-section {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        width: 100%;
        p {
            margin: 0px 0;
            // font-size: 0.9em;
        }
    }
    .upper-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        .project-name {
            // font-weight: bolder;
            font-family: 'Lato Bold', sans-serif;
            font-size: $font-size-large;
            margin: 0;
            margin-right: auto;
            text-align: left;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .lower-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        .icon-value-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            p,
            svg {
                color: $brand-middle-grey;
            }
        }
    }
    .date {
        font-weight: 500;
        color: #777;
        font-size: $font-size-default;
        margin-top: 4px;
    }
}

button {
    cursor: pointer;
    &:hover {
        &.rejected {
            background-color: darken(rgba(#d52214, 0.2), 8%);
        }

        &.referred {
            background-color: rgba(#e0b80e, 0.2);
        }

        &.approved {
            background-color: rgba(#389e0d, 0.2);
        }
        &.ai-approved {
            background-color: rgba(#1976d2, 0.2);
        }
    }
}
.status-indicator {
    p {
        font-size: 12px;
        font-family: 'Lato Bold', sans-serif;
        margin: 0px;
    }
    font-size: 1em;
    padding: 2px 6px;
    font-family: 'Lato Bold', sans-serif;
    margin: 5px 0px;
    border-radius: 15px;
    text-align: center;
    min-width: 40px;
    &:is(button) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    gap: 10px;
    border: none;
    &.big {
        p {
            font-size: 16px;
            font-family: 'Lato Bold', sans-serif;
        }
        padding: 4px 12px;
    }
    &.rejected {
        color: #d52214;
        background-color: rgba(#d52214, 0.2);
    }

    &.referred {
        color: #e0b80e;
        background-color: rgba(#e0b80e, 0.2);
    }

    &.approved {
        color: #389e0d;
        background-color: rgba(#389e0d, 0.2);
    }
    &.ai-approved {
        color: #1976d2;
        background-color: rgba(#1976d2, 0.2);
    }
}

.filter-controls {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    // width: 100%;
    // overflow: auto;
    h2 {
        margin: 0;
        margin-right: 20px;
        margin-right: auto;
        // flex: 1;
    }
    .filter-buttons {
        display: flex;
        flex-direction: row;
        justify-items: center;
        max-width: 100%;
        margin-left: auto;
        // overflow-x: auto;
    }
    .search-container {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 8px;
        // flex: 1;
    }
    &.mobile {
        overflow-x: auto;
        padding-bottom: 315px;
        margin-bottom: -300px;
        // height: auto;
    }
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
}

.search-container .search-input {
    padding-left: 30px;
    height: 20px;
    font-size: 1em;
}

.search-container .search-icon {
    position: absolute;
    color: #cccccc;
    left: 10px;
}

.search-container input:focus {
    outline: none;
    border-color: $brand-dark-grey;
}

.page-link span:first-child {
    margin-right: 8px;
    /* Adjust as needed */
}

.user-input-table {
    display: flex;
    justify-content: space-between;
    margin: auto;
    gap: 20px;
    margin: 0px;
}

.user-input-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
}

.user-input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
}

.user-input-label {
    font-family: 'Lato Bold', sans-serif;
    min-width: 120px;
}

.user-input-value {
    flex-grow: 1;
    // width: auto;
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for webkit */
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

hr {
    border: 0;
    height: 1px;
    min-height: 1px;
    background-color: #d9d9d9;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}

.pagination-boundary {
    height: 30.5px;
    width: 1px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.circular-icon {
    cursor: pointer;
    padding: 5px;
    border-radius: 100%;
    &:hover {
        background-color: $light-grey-hover;
    }
}

.approval-container {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    // height: 28px;
    // margin-bottom: 10px;
    h2 {
        margin: 0px;
    }
    .approval-actions {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        margin-left: auto;
        .approval-delete {
            border: none;
            background-color: #fff;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            svg {
                color: $brand-dark-grey;
                font-size: 1.4em;
            }
            &:hover {
                background-color: $light-grey-hover;
            }
        }
    }
}

.ellipsis-options-container {
    position: relative;
    display: inline-block;
    .ellipsis-button {
        border: none;
        background-color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        svg {
            color: $brand-dark-grey; // Adjust color as needed
            font-size: 1.4em;
        }
        &:hover {
            background-color: #f7f7f7;
        }
    }
    .options-modal {
        position: absolute;
        top: 35px;
        right: 0;
        background: white;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 999;
        width: 180px;
        text-align: center;
        .options-modal-content {
            display: flex;
            flex-direction: column;
            .modal-button {
                color: $brand-dark-grey;
                background: white;
                border-radius: 10px;
                border: none;
                padding: 6px 12px;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 10px;
                justify-content: left;
                font-family: 'Lato Bold', sans-serif;
                &:hover {
                    background: $light-grey-hover;
                }
            }
        }
    }
}

.toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.summary-container {
    width: 100%;
}

.summary-title {
    font-weight: bold;
    margin-right: 20px;
    min-width: 120px;
}

.summary-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    gap: 20px;
}

.summary-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    margin: 0;
    padding: 0;
}

.summary-item {
    margin-bottom: 5px;
}

.rules-check-controls {
    h3 {
        margin: 0px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    // height: 28px;
    margin-bottom: 10px;
}

.rule-checks {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .rules-check-card {
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        padding: 8px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        &.is-focussed {
            background: repeating-linear-gradient(
                45deg,
                #ededed,
                #ededed 10px,
                $light-grey-hover 10px,
                $light-grey-hover 20px
            ) !important;
            cursor: pointer;
        }

        &.can-focus {
            cursor: pointer;
            &:hover {
                background-color: $light-grey-hover;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
                transform: translateY(-1px); /* Slight lift effect */
            }
            // background: repeating-linear-gradient(
            //     45deg,
            //     transparent,
            //     transparent 10px,
            //     $light-grey-hover 10px,
            //     $light-grey-hover 20px
            // );
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
            background-color: $light-grey-hover;

            // box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        }
        .decision-colour-box {
            width: 4px;
            min-width: 4px;
            height: auto;
            align-self: stretch;
            border-radius: 2px;
            display: inline-block;
        }
        .content-container {
            width: 100%;
            .upper-container {
                display: flex;
                flex-direction: row;
                // margin-bottom: 8px;
                .text-container {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    p {
                        margin: 0px;
                        // margin-bottom: 4px;
                        .highlighted {
                            font-family: 'Lato Bold', sans-serif;
                            font-style: italic;
                        }
                    }
                    .check-name {
                        font-family: 'Lato Bold', sans-serif;
                        font-size: 1.12em;
                        margin-bottom: 3px;
                        gap: 5px;
                        display: flex;
                        align-items: center;
                    }
                    .check-summary {
                        // margin-bottom: 10px;
                        color: $brand-dark-grey;
                    }
                    .check-summary-bullets {
                        padding-left: 20px;
                        margin: 0px;
                        margin-top: 3px;
                        li {
                            color: $brand-dark-grey;
                        }
                    }
                }
                // width: 100%;
                .button-container {
                    margin-left: auto;
                    display: flex;
                    flex-direction: row;
                    gap: 4px;
                    min-width: 83px;
                    button {
                        display: flex;
                        padding: 0px 6px;
                        height: 25px;
                        width: 25px;
                        border-radius: 5px;
                        align-items: center;
                        justify-content: center;
                        border: none;
                        background-color: transparent;
                        cursor: pointer;
                        // &:hover,
                        // &.focussed {
                        //     background-color: $light-grey-hover;
                        // }
                        &:first-child {
                            margin-left: auto;
                        }
                        svg {
                            color: $brand-dark-grey;
                            height: 1.2em;
                        }
                    }
                }
            }
            .details-container {
                // margin-top: 10px;
            }
        }
    }
}

.spec-check-details-text-changes {
    width: 100%;
    td {
        padding: 0px;
        &:first-child {
            padding-right: 5px;
        }
        &:nth-child(2) {
            padding-left: 5px;
        }
        span {
            // font-size: 0.9em;
            color: $brand-dark-grey;
            &.yellow {
                background: rgba($color: #e0b80e, $alpha: 0.4);
            }
            &.green {
                background: rgba($color: #389e0d, $alpha: 0.4);
            }
            &.red {
                text-decoration: line-through;
                // color: transparent;
                background: rgba($color: red, $alpha: 0.4);
            }
        }
    }
    thead {
        td {
            color: $brand-dark-grey;
            font-family: 'Lato Bold';
        }
    }
}

.symbol-image {
    max-width: 100%;
    max-height: 80px;
}

.spec-check-details-text-changes-new {
    width: 100%;
    tr {
        td {
            padding: 0px;
            padding-bottom: 12px;
            gap: 10px;
            color: $brand-dark-grey;
            &:first-child {
                width: 50px;
                font-family: 'Lato Bold';
            }
            &.size {
                display: flex;
                align-items: center;
                gap: 5px;
            }
            p {
                margin: 0px;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
            span {
                color: $brand-dark-grey;
                &.yellow {
                    background: rgba($color: #e0b80e, $alpha: 0.4);
                }
                &.green {
                    background: rgba($color: #389e0d, $alpha: 0.4);
                }
                &.red {
                    text-decoration: line-through;
                    // color: transparent;
                    background: rgba($color: red, $alpha: 0.4);
                }
            }
        }
        &:last-child {
            td {
                padding-bottom: 0px;
            }
        }
    }
}

.spec-check-details-table-header {
    font-family: 'Lato Bold', sans-serif;
    color: $brand-dark-grey;
}

.spec-check-details-table {
    width: calc(100% - 20px);
    border-collapse: collapse; // border-bottom: 1px solid #d9d9d9;
    // border-top: 1px solid #d9d9d9;
    margin-left: 10px;
    margin-right: 10px;
    .header {
        span {
            font-family: 'Lato Bold', sans-serif !important;
        }
    }
    tr:not(:last-child) td {
        border-bottom: 1px solid #d9d9d9;
    }
    td {
        padding: 0px;
        padding-right: 10px;
        padding-top: 2px;
        padding-bottom: 2px;
        &:last-child {
            padding-right: 0px;
        }
        span {
            // font-size: 0.9em;
            color: $brand-dark-grey;
            &.yellow {
                background: rgba($color: #e0b80e, $alpha: 0.4);
            }
            &.green {
                background: rgba($color: #389e0d, $alpha: 0.4);
            }
            &.red {
                text-decoration: line-through;
                background: rgba($color: red, $alpha: 0.4);
            }
            &.red-transparent {
                color: transparent;
                background: rgba($color: red, $alpha: 0.4);
            }
        }
    }
    thead {
        td {
            color: $brand-dark-grey;
            font-family: 'Lato Bold';
        }
    }
}

.view-more-less-button {
    margin-top: 5px;
    // width: 200px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-family: 'Lato Bold';
    padding: 6px 10px;
    border-radius: 10px;
    &:hover {
        background-color: $light-grey-hover;
    }
}

.detail-image {
    max-width: 100%;
    height: auto;
}

.detail-image-container {
    display: block;
}

.detail-image-container img {
    object-fit: contain;
}

.asset-details-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    height: calc(100vh - 110px);
    width: 100%;
    // overflow: hidden;
    .left-container {
        flex: 3;
        position: relative;
    }
    .right-container {
        flex: 2;
        display: flex;
        flex-direction: column;
        // margin-left: 630px;
        width: 100%;
        &.mobile {
            margin-left: 0px;
        }
        overflow-y: hidden;
    }
}

/* Hide scrollbar for all browsers except IE */

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

/* Optional: Add background color to the scrollbar track */

::-webkit-scrollbar-track {
    background: transparent;
}

/* Custom scrollbar thumb (the part that can be dragged) */

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 3px solid transparent;
    background-clip: padding-box;
}

/* Optional: Hover effect for scrollbar thumb */

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Hide scrollbar for IE and Edge */

* {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.pan-rotate-container {
    margin-top: 20px;
    &:not(.mobile) {
        width: 400px;
        height: 400px;
    }
}

.pagination li:first-child .page-link,
.pagination li:last-child .page-link {
    font-family: 'Lato Bold', sans-serif;
}

.pagination li:hover:not(.active):not(.disabled):not(.ellipsis) {
    text-decoration: underline;
    cursor: pointer;
}

.pagination .page-link {
    border: none;
    color: $brand-dark-grey;
    text-decoration: none;
    padding: 8px 10px;
    // gap: 10px;
}

.pagination-container {
    background-color: white;
    z-index: 1000;
    border-color: black;
    padding: 0;
    &:not(.mobile) {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 1px;
        border-radius: 40px;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
    }
    .pagination {
        list-style: none;
        display: flex;
        justify-content: center;
        flex: 1;
        padding: 0px;
        margin-top: 4px;
        margin-bottom: 4px;
        li {
            padding: 0px;
            margin: 0 5px;
            background-color: #fff;
            border: none;
            &.disabled {
                cursor: default;
                opacity: 0.4;
            }
        }
        .page-item {
            background-color: transparent;
            .page-link {
                &.active {
                    border: 1px solid $brand-dark-grey;
                    border-radius: 5px;
                    color: $brand-dark-grey;
                    font-family: 'Lato Bold', sans-serif;
                    cursor: default;
                    // padding:10px;
                }
            }
        }
    }
}

.back-to-library-button {
    // color: #3268cd;
    border: none;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    // width: 25.5px;
    height: 25px;
    border-radius: 10px;
    gap: 5px;
    margin-bottom: 5px;
    svg {
        color: $brand-dark-grey;
        height: 1.2em;
    }
    p {
        font-size: 1.2em;
        color: $brand-dark-grey;
    }
    &:hover {
        p {
            text-decoration: underline;
        }
    }
}

.no-results-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
}

.chart-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    :first-child {
        margin-bottom: 10px;
    }
    .chart-container {
        flex: 1;
    }
}

.viewer-container {
    position: relative;
    width: 900px;
    height: 90vh;
    background-color: #fafafa;
    border: 1px solid #fafafa;
    /* Light grey background */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.pdf-canvas {
    cursor: grab;
    will-change: transform;
}

.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    h1 {
        text-align: center;
    }
}

.activity-feed {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-right: 10px;
    .activity-item {
        display: flex;
        flex-direction: row;
        gap: 15px;
        .left-side {
            position: relative;
            .line {
                position: absolute;
                top: 15px;
                bottom: 0;
                height: 100%;
                left: calc(50% - 0.5px);
                width: 1px; // Line thickness
                background: #d9d9d9; // Line color
                z-index: 0;
            }
            .activity-avatar {
                position: relative;
                z-index: 2;
                width: 40px;
                height: 40px;
                border-radius: 20px;
                &.unknown {
                    background-color: $brand-color-hover;
                }
            }
        }
        .right-side {
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 5px;
            .content {
                font-size: 1.1em;
                display: inline;
                line-height: 1.5;
                .highlighted {
                    font-size: 1em;
                    font-family: 'Lato Bold', sans-serif;
                }
            }
            .text-field {
                background-color: $light-grey-hover;
                padding: 8px;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                gap: 2px;
                p {
                    font-size: 1.1em;
                    margin: 0px;
                }
                .button-container {
                    margin-left: auto;
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                    button {
                        font-family: 'Lato Bold', sans-serif;
                        font-size: 1.1em;
                        border-radius: 10px;
                        border: none;
                        cursor: pointer;
                        padding: 2px 8px;
                        &.submit {
                            // background-color: $brand-color-hover;
                            background-color: green;
                            color: white;
                            border: 1px solid green;
                        }
                        &.cancel {
                            border: 1px solid #cdcdcd;

                            background-color: transparent;
                            // color: white;
                        }
                    }
                }
            }
            .time {
                color: $brand-middle-grey;
            }
        }
    }
}
.activity-controls {
    h3 {
        margin: 0px;
    }
    display: flex;
    flex-direction: row;
    align-items: top;
    margin-bottom: 15px;
    margin-right: 10px;
    .comment-button {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: white;
        border: none;
        // color: white;
        border-radius: 10px;
        font-family: 'Lato Bold', sans-serif;
        font-size: 1.1em;
        padding: 2px 8px;
        border: 1px solid $brand-middle-grey;
        &:hover {
            background-color: $light-grey-hover;
        }
    }
}

.outcome-selector {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .outcome-button {
        flex: 1;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        padding: 5px 5px;
        .upper-container {
            display: flex;
            flex-direction: row;
            // margin-right: auto;
            gap: 10px;
            align-items: center;
            .approval-icon {
                // width: 10px;
                svg {
                    height: 1.2em;
                }
            }
            .label {
                font-size: 1.2em;
                font-family: 'Lato Bold', sans-serif;
            }
        }

        p {
            margin: 0px;
        }
    }
}

.approval-status-modal {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .approval-status-button {
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        padding: 5px 20px;
        .approval-icon {
            width: 20px;
            svg {
                height: 1.3em;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 2px;
            align-items: flex-start;

            p {
                margin: 0px;
            }
            .label {
                font-size: 1.2em;
                font-family: 'Lato Bold', sans-serif;
            }
        }
    }
}

.pdf-page-selector-content {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    h2 {
        margin-top: 5px;
        text-align: center;
    }
    .delete-text {
        margin: 0px;
        text-align: center;
        font-size: 1.05em;
    }
    .selector-row {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        margin: auto;
        p {
            margin: 0px;
        }
    }
    .button-row {
        display: flex;
        flex-direction: row;
        margin: auto;
        gap: 10px;
        .btn-secondary {
            // margin-left: auto;
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            margin-top: 20px;
            // width: 80px;
            //   height: 24px;
            justify-content: center;
            align-items: center;
            // font-size: $font-size-large;
            // color: white !important;
            // background-color: $brand-dark-grey;
            color: rgb(65, 65, 65);
            border: 1px solid $brand-dark-grey;
            text-align: center;
            cursor: pointer;
            &:hover {
                background-color: $light-grey-hover;
            }
        }
        .btn-primary {
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            margin-top: 20px;
            // width: 80px;
            //   height: 24px;
            justify-content: center;
            align-items: center;
            // font-size: $font-size-large;
            color: white !important;
            background-color: $brand-dark-grey;
            text-align: center;
            margin-left: auto;
            cursor: pointer;
            &:hover {
                background-color: $brand-dark-grey;
            }
        }
    }
}

.image-warning-content {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    h2 {
        margin-top: 5px;
        text-align: center;
    }
    .delete-text {
        margin: 0px;
        text-align: center;
        font-size: 1.05em;
    }
    .selector-row {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        margin: auto;
        p {
            margin: 0px;
        }
    }
    .button-row {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 10px;
        .btn-secondary {
            // margin-left: auto;
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            margin-top: 20px;
            // width: 80px;
            //   height: 24px;
            justify-content: center;
            align-items: center;
            // font-size: $font-size-large;
            // color: white !important;
            // background-color: $brand-dark-grey;
            color: rgb(65, 65, 65);
            border: 1px solid $brand-dark-grey;
            text-align: center;
            cursor: pointer;
            &:hover {
                background-color: $light-grey-hover;
            }
        }
        .btn-primary {
            border-radius: 5px;
            border: none;
            font-family: 'Lato Bold', sans-serif;
            padding: 8px 16px;
            margin-top: 20px;
            // width: 80px;
            //   height: 24px;
            justify-content: center;
            align-items: center;
            // font-size: $font-size-large;
            color: white !important;
            background-color: $brand-dark-grey;
            text-align: center;
            margin-left: auto;
            cursor: pointer;
            &:hover {
                background-color: $brand-dark-grey;
            }
        }
    }
}

.no-organization-container {
    text-align: center;
    margin-top: 40px;
    padding: 40px;
    background: #f8f9fa;
    width: 300px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 3px;
    .no-org-title {
        margin-top: 0px;
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: center;
    }

    .no-org-text {
        font-size: 1rem;
        margin-bottom: 10px;
        color: #555;
        text-align: left;
        text-align: justify;
        // text-align-last: justify; /* Forces last line to justify */

        .no-org-link {
            color: #007bff;
            text-decoration: none;
            font-family: 'Lato Bold';
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .sign-out-wrapper {
        margin-top: 15px;
        .sign-out-button {
            background-color: #333;
            color: white;
            padding: 4.5px 9px;
            border-radius: 5px;
            width: 100%;
            font-family: 'Lato Bold';
            border: none;
            cursor: pointer;
            &:hover {
                background-color: #555;
            }
        }
    }
}

.cl-form.hidden {
    display: none;
}

.cl-dividerRow.hidden {
    display: none;
}
